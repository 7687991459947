.exhibition-card{
    height:200px;
    align-items: center;
    @include responsive(mobile) {
        display: none;
    }
    .exhibition-thumbnail{
        background-color:white;
    }
}

.exhibition-mobile-card{
    display: none;
    @include responsive(mobile) {
        display: flex;
    }
    .exhibition-thumbnail{
        background-color:white;
    }
}

.exhibition-card-wrapper + .exhibition-card-wrapper {
    @include responsive(mobile) {
        margin-top: 72px;
    }
}