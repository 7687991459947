.exhibition-page {
  display: grid;
  gap: 86px;
  grid-template-columns: 1134px 1fr;
  @include responsive(tablet) {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }
  .exhibition-type-selector-wrapper {
    display: flex;
    flex-direction: row;
    @include responsive(tablet) {
      flex-direction: column;
    }
  }
}
