.user-board-list {
  display: grid;
  width: 100%;
  grid-gap: 8px;
  grid-row-gap: 96px;

  grid-template-columns: 80px 1fr 80px 80px 80px;
  @include responsive(mobile) {
    display: none;
  }
}

.user-board-mobile-list {
  display: none;
  @include responsive(mobile) {
    display: flex;
  }
}

.user-board-list-font {
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 700;
}

.user-board-list-title-bg {
  background-color: $user-board-list-title-bg;
}

.user-board-list-title-border {
  border-top: 1px solid $neutrals-1;
}