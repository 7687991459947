.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.4s;
}

.btn-left {
  left: 0;
}
.btn-right {
  right: 0;
}

.main-btn-left {
  left: 0px;
  height: 100%;
  padding: 0 30px;
  overflow: hidden;
  @include responsive(tablet) {
    padding: 0 20px;
  }
  @include responsive(foldable) {
    padding: 0 10px;
  }
  @include responsive(mobile) {
    padding: 0;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: all 0.4s;
    overflow: hidden;
  }
  span {
    opacity: 0.4;
    transition: all 0.4s;
  }
  &:hover span {
    opacity: 1;  
  }
  &:hover::after {
    opacity: 1;
    background: linear-gradient(to right, rgba(0,0,0,0.30) 0%,rgba(0,0,0,0) 100%);
  }
}

.main-btn-right {
  right: 0px;
  height: 100%;
  padding: 0 30px;
  overflow: hidden;
  @include responsive(tablet) {
    padding: 0 20px;
  }
  @include responsive(foldable) {
    padding: 0 10px;
  }
  @include responsive(mobile) {
    padding: 0;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: all 0.4s;
    overflow: hidden;
  }
  span {
    opacity: 0.4;
    transition: all 0.4s;
  }
  &:hover span {
    opacity: 1;  
  }
  &:hover::after {
    opacity: 1;
    background: linear-gradient(to left, rgba(0,0,0,0.30) 0%,rgba(0,0,0,0) 100%);
  }
}
