.artist-detail-page {
  .artist-detail-profile-section {
    .artist-detail-background-weird-item {
      @include responsive(mobile) {
        display: none;
      }
    }
    .artist-detail-profile{
      display: flex;
      flex-direction: row;
      padding: 100px 180px;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      @include responsive(tablet) {
        padding: 80px 24px;
      }
      @include responsive(mobile) {
        flex-direction: column-reverse;
        padding: 0px 0px 40px 0px;
        justify-content: center;
        transform: translateY(-94px);
      }
      .artist-detail-profile-items{
        width: 70%;
        justify-content: center;
        align-items: start;
        @include responsive(mobile) {
          justify-content: center;
          align-items: center;
        }
        .artist-detail-profile-name{
          margin-top: 0px;
          @include responsive(mobile) {
            margin-top: 32px;
          }

        }
      }
      .artist_sns_icon + .artist_sns_icon {
        margin-left: 16px;
      }
    }
  }

  .artist-detail-divider-wrapper {
    display: flex;
    height: 110px;
    @include responsive(tablet) {
      display: none;
    }
    @include responsive(mobile) {
      display: flex;
      height: 200px;
    }
    .artist-detail-divider{
      height: 1px;
      width: 150px;
      border-bottom: solid 1px #000000;
      border-right: none;
      @include responsive(mobile) {
        height: 150px;
        width: 1px;
        border-bottom: none;
        border-right: solid 1px #000000;
      }
    }
  }

  .artist-detail-section-1{
    display: flex;
    flex-direction: row;
    padding: 40px 0px;
    @include responsive(tablet) {
      padding: 40px 0px;
    }
    @include responsive(mobile) {
      flex-direction: column;
      padding: 0px 0px 40px 0px;
    }

    .artist-detail-section-1-contents-container{
      max-width: 550px;
      .artist-detail-section-1-title{
        margin-top: 52px;
        @include responsive(tablet) {
          margin-top: 48px;
        }
        @include responsive(mobile) {
          margin-top: 40px;
        }
      }
    }
    .right-container {
      margin-top: 0px;
      @include responsive(mobile) {
        margin-top: 80px;
      }
    }

    .artist-detail-section-1-contents-container + .artist-detail-section-1-contents-container {
      margin-left: 56px;
      @include responsive(tablet) {
        margin-left: 24px;
      }
      @include responsive(mobile) {
        margin-left: 0px;
      }
    }
  }

  .artist-detail-section-2{
    margin-top: 244px;
    @include responsive(mobile) {
      margin-top: 56px;
    }
    .artist-description-bg {
      padding-right: calc(50% - 550px - 28px);
      z-index: -1;
      @include responsive(mobile) {
        display: none;
      }
    }

    .artist-detail-section-2-container {
      display: flex;
      flex-direction: row;
      z-index: 10;
      padding-bottom: 212px;
      @include responsive(mobile) {
        flex-direction: column;
        padding-bottom: 56px;
      }
      .artist-detail-section-2-pc-image{
        @include responsive(mobile) {
          display: none;
        }
      }
      .artist-detail-section-2-mobile-image{
        display: none;
        @include responsive(mobile) {
          display: flex;
        }
      }
      .artist-detail-section-2-title-container{
        max-width: 550px;
        margin-right: 0px;
        margin-left: 0px;
        @include responsive(mobile) {
          margin-right: auto;
          margin-left: auto;
        }
        .artist-detail-section-2-title{
          font-size: 56px;
          line-height: 68px;
          @include responsive(mobile) {
            font-size: 36px;
            line-height: 48px;
          }
        }
      }
      .right-container {
        margin-top: 0px;
        @include responsive(mobile) {
          margin-top: 80px;
        }
      }
      .artist-detail-section-2-contents-container + .artist-detail-section-2-contents-container {
        margin-left: 56px;
        @include responsive(tablet) {
          margin-left: 24px;
        }
        @include responsive(mobile) {
          margin-left: 0px;
        }
      }
    }
  }

  .artist-detail-section-3{
    display: flex;
    flex-direction: row;
    padding: 40px 0px;
    @include responsive(tablet) {
      padding: 40px 0px;
    }
    @include responsive(mobile) {
      flex-direction: column;
      padding: 0px 0px 40px 0px;
    }

    .artist-detail-section-3-contents-container{
      max-width: 550px;
      .artist-detail-section-3-title{
        margin-top: 52px;
        font-size: 56px;
        @include responsive(tablet) {
          margin-top: 48px;
        }
        @include responsive(mobile) {
          font-size: 36px;
          margin-top: 40px;
        }
      }
      .artist-detail-section-3-contents-image{
        max-width: 386px;
        @include responsive(mobile) {
          max-width: none;
        }
      }
    }
    .right-container {
      margin-top: 0px;
      @include responsive(mobile) {
        margin-top: 0px;
      }
    }

    .artist-detail-section-3-contents-container + .artist-detail-section-3-contents-container {
      margin-left: 56px;
      @include responsive(tablet) {
        margin-left: 24px;
      }
      @include responsive(mobile) {
        margin-left: 0px;
      }
    }
  }

  .artist-detail-other-items-section {
    margin-top: 244px;
    @include responsive(mobile) {
      margin-top: 56px;
    }
  }
}
