.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 18;

  .backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }

  .modal-in-klip-backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .modal-card {
    position: relative;
    border: 1px solid $neutrals-5;
    width: 454px;
    z-index: 2;
  }

  .large-modal-card {
    position: relative;
    border: 1px solid $neutrals-5;
    z-index: 2;
    width: $col-2;
    @include responsive(foldable) {
      max-width: 396px;
      height: 90%;
      padding: 20px;
    }
    @include responsive(320px) {
      max-width: 100%;
      height: 90%;
      padding: 20px;
    }
  }

  .modal-card-inner {
    @include responsive(tablet) {
      text-align: center;
      padding: 64px 32px;
    }
    @include responsive(foldable) {
      padding: 0;
      height: 100%;
    }
    @include responsive(320px) {
    }
    .close-button {
      width: 24px;
      height: 24px;
      top: 0;
      right: 0;

      .icon {
        fill: $neutrals-2;
      }

      &:hover .icon {
        fill: $neutrals-1;
      }
    }

    .notice-list {
      list-style: disc;
    }
    .burnBtn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      @include responsive(foldable) {
        gap: 10px;
        button {
          min-width: 130px;
          margin-top: 0;
        }
      }
    }
    .link-btn {
      height: 52px;
      min-width: 192px;
      padding: 12px;
      border-radius: 24px;
      background-color: $primary;
      font-size: 16px;
      color: $white;
      &:hover {
        background-color: $primary-dark;
      }
      @include responsive(foldable) {
        margin-top: 0;
      }
      @include responsive(mobile) {
        margin-top: 0;
      }
    }
    .link-btn.burn {
      background-color: $secondary-red;
      border-color: $secondary-red;
      &:hover {
        background-color: $warning;
        border-color: $warning;
      }
    }
  }
  .modal-btns-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 32px;
    @include responsive(tablet) {
      flex-direction: column;
      margin-top: 0px;
    }
    .modal-btn {
      width: 110px;
      margin-left: 8px;
      margin-right: 8px;
      @include responsive(tablet) {
        flex-direction: column;
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 8px;
      }
    }
  }
}
