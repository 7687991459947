.search-market {
  width: 280px;

  .input-base {
    border: 1px solid $neutrals-3;
    cursor: text;

    &:hover {
      border: 1px solid $neutrals-1;
    }

    &.activate {
      outline: $neutrals-1 2px solid;
    }
  }

  .icon {
    @include absoluteCenterY;
    right: 16px;
    width: 20px;
    height: 20px;

    &:hover {
      background-color: $neutrals-6;
    }
  }

  .search-popup {
    z-index: $component-zindex;

    .search-results {
      min-height: 40vh;
      max-height: 60vh;

      .category {
        top: 0;
      }
    }
  }
}
