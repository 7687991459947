.responsive-background-width {
  max-width: $col-4;
  min-width: $col-1;
  width: 100%;
  @include responsive(ipadpro) {
    width: 90%;
  }
  @include responsive(tablet) {
    width: 90%;
  }
  @include responsive(foldable) {
    width: 90%;
  }
}
