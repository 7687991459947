.exhibition-type-btn {
  padding: 18px 24px;
  border-radius: 28px;
  height: 56px;
  margin-top: 60px;
  &.selected {
    background-color: #1734ea;
    color: white;
  }
}

.artist-type-btn {
  padding: 18px 24px;
  height: 56px;
  margin-top: 8px;
  &.selected {
    background-color: rgba($color: $primary, $alpha: 0.05);
    color: black;
    border-radius: 8px 8px 0 0;
  }
}
