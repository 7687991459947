.arrow-box-wrapper {
  position: absolute;
  left: 23%;
  top: -10%;
  @include responsive(ipadpro) {
    left: 18%;
    top: -10%;
  }
  @include responsive(tablet) {
    left: 3%;
    top: -5%;
  }
  @include responsive(mobile) {
    left: 5%;
    top: 20%;
  }
}

.arrow-box {
  position: relative;
  background: #222222;
  border: 1px solid #222222;
}
.arrow-box:after,
.arrow-box:before {
  right: 100%;
  top: 50%;
  content: '';
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.arrow-box:after {
  border-color: rgba(34, 34, 34, 0);
  border-right-color: #222222;
  border-width: 7px;
  margin-top: -7px;
}
.arrow-box:before {
  border-color: rgba(34, 34, 34, 0);
  border-right-color: #222222;
  border-width: 8px;
  margin-top: -8px;
}
