@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff) format('woff'), url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2'),
    url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff) format('woff'),
    url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2'),
    url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@import './reset';
@import './constants';
@import './mixins';
@import './common';
@import './flexbox';
@import './gridbox';
@import './typography';
@import './overrides';
@import './animation';
@import './antd';
@import './background';
@import './preview';
@import './style';
@import './modalstyle';

// page
@import './pages/about';
@import './pages/accountSetting';
@import './pages/artDetail';
@import './pages/artist';
@import './pages/artistDetail';
@import './pages/exhibition';
@import './pages/exhibitionDetail';
@import './pages/create';
@import './pages/main';
@import './pages/market';
@import './pages/myPage';
@import './pages/news';
@import './pages/signup';
@import './pages/UserBoard';

//main
@import './components/main/notableArtistCard';
@import './components/main/statistics';

//market
@import './components/market/searchMarket';

//art-detail
@import './components/art-detail/artAuth';
@import './components/art-detail/generalDetail';

//artist-detail
@import './components/artist-detail/artistProfile';

//exhibition
@import './components/exhibitionCard';
@import './components/exhibitionList';

//about
@import './components/about/newsSticker';

//listing
@import './components/listing/listingModal';

//connect
@import './components/connect/backgroundGallery';

//carousel
@import './components/carousel/carousel';
@import './components/carousel/indicator';
@import './components/carousel/carouselButton';

//carousel-card
@import './components/carousel-card/newsSlide';
@import './components/carousel-card/newsSlideLong';

//user-board
@import './components/user-board/userBoadList';
@import './components/user-board/userBoadListItem';
@import './components/user-board/search';
@import './components/user-board/userBoardUpdate';

//right-banner
@import './components/rightBanner';

// common component
@import './components/loading';
@import './components/featuredSection';
@import './components/footer';
@import './components/imageContainer';
@import './components/marketCardList';
@import './components/marketListCard';
@import './components/modal';
@import './components/nav';
@import './components/newsCard';
@import './components/onClickCopyButton';
@import './components/pageHeader';
@import './components/artistProfileList';
@import './components/profileSticker';
@import './components/title';
@import './components/curatedCollectionCard';
@import './components/exhibitionType';
