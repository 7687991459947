.modal-card-inner {
  .minting-wrapper {
    max-width: 918px;
    background-color: white; //   height: 100px;
    @include responsive(foldable) {
      padding: 0;
    }
    @include responsive(320px) {
      padding: 0;
    }
    .chian-wrapper {
      grid-template-columns: repeat(2, minmax(180px, 1fr));
      grid-auto-rows: auto;
      gap: 16px;
      .select-chain {
        min-height: 400px;
        &:hover {
          transform: translateY(-5px);
          box-shadow: 5px 5px 5px rgba(110, 56, 56, 0.4);
        }
      }
    }

    .nft-form-box {
      flex: 1 1 auto;
      .form-input {
        margin-top: 2.7rem;
      }
      .form-input:first-child {
        margin-top: 5.4rem;
        @include responsive(mobile) {
          margin-top: 2.7rem;
        }
      }

      .upload-img-box {
        border: 2px dashed $neutrals-5;
        min-height: 150px;
        @include responsive(mobile) {
          height: 140px;
          padding: 10px;
        }
        .close-icon {
          top: 4%;
          right: 4%;
          width: 40px;
          height: 40px;
          @include responsive(mobile) {
            width: 24px;
            height: 24px;
          }
        }
        .upload-img {
          width: 160px;
          height: 160px;
          @include responsive(mobile) {
            width: 120px;
            height: 120px;
          }
        }
        .img-input {
          position: fixed;
          left: -100vw;
        }
      }

      .price-input-box {
        min-height: 48px;
        transition: all 0.12s ease-in-out 0s;

        .arrow {
          bottom: -5px;
        }

        input:disabled {
          cursor: not-allowed;
        }
      }

      // form {
      //   @include responsive(mobile) {
      //     margin-top: 14px;
      //   }
      // }
    }
    .nft-preview-box {
      flex-basis: 255px;
      top: 90px;
      @include responsive(foldable) {
        display: none;
      }
      .nft-preview-inner-box {
        height: 384px;
        .nft-preview-img-warapper {
          .nft-preview-img {
            width: 210px;
            height: 210px;
          }
        }
      }
    }
    .burnBtn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }
}

.text-hover-cancle {
  span {
    color: $neutrals-6;
    &:hover {
      color: $neutrals-6;
    }
  }
}
