.news-slide-card {
  transition: transform 250ms ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }
  .news-thumbnail {
    transition: box-shadow 250ms ease-in-out, transform 250ms ease-in-out;
    height: auto;
    border-radius: 24px;
    &:hover {
      @include default-box-shadow;
    }
  }
}

.description-wrapper {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
