.account-setting-inner {
  width: $col-2;
  @include responsive(foldable) {
    width: $col-1;
  }
}

.account-update-head {
  grid-template-columns: 360px 1fr;
  border-bottom: 1px solid $neutrals-1;
  @include responsive(foldable) {
    grid-template-columns: 272px 1fr;
  }
  .inform {
    display: block;
    @include responsive(foldable) {
      display: none;
    }
  }
  .profile-display {
    .user-img-frame {
      width: 120px;
      height: 120px;
      right: 0;
      @include responsive(foldable) {
        width: 88px;
        height: 88px;
        bottom: -62px;
      }
    }
    .icon.add {
      bottom: 0;
      right: 0;
      @include responsive(foldable) {
        bottom: -62px;
        width: 36px;
        height: 36px;
      }
    }
  }
}

.account-update-body {
  display: grid;
  grid-template-rows: auto;
  gap: 12px;

  .input-box {
    display: grid;
    grid-template-columns: 194px 1fr;
    @include responsive(foldable) {
      grid-template-columns: 96px 1fr;
    }
    .label {
      padding-top: 16px;
      font-weight: 600;
    }

    .text-input {
      border: 1px solid $neutrals-5;
      &:focus {
        outline: none;
      }
    }

    .wallet {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border: 1px solid $primary;
    }
  }
}
.submit-button-wrapper {
  width: 100%;
}

.checkbox-input {
  @include responsive(foldable) {
    overflow: hidden;
  }
}
