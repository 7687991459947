.news-card {
  transition: transform 250ms ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }

  .news-thumbnail {
    transition: box-shadow 250ms ease-in-out, transform 250ms ease-in-out;
    height: $col-1;
    &:hover {
      @include default-box-shadow;
    }

    .news-img {
      width: $col-1;
      height: $col-1;
    }
  }
  .news-content {
    width: $col-1;
  }
}

.description-wrapper {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
