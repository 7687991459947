.featured-section {
  width: $col-4;
  @include responsive(ipadpro) {
    width: 1092px;
  }
  @include responsive(tablet) {
    width: 754px;
  }
  @include responsive(foldable) {
    width: $col-1;
  }
}

.featured-artist-section {
  width: $col-4;
  @include responsive(ipadpro) {
    width: 1092px;
  }
  @include responsive(tablet) {
    width: 754px;
  }
  @include responsive(foldable) {
    width: $col-1;
  }
}
