.right-banner-wrapper {
     display: flex;
     flex-direction: column;
     @include responsive(tablet) {
          flex-direction: row;
          overflow-x: scroll;
     }
     @include responsive(mobile) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
     }
     .right-banner {
          width: 332px;
          height: 506px;
          border-radius:20px;
          overflow: hidden;
          @include responsive(mobile) {
               img {
                    width: 100%;
                    height: 100%;
               }
               width: 100%;
               max-width: 372px;
               height: 178px;
          }
     }
     .right-banner + .right-banner{
          margin-top: 1rem;
          margin-left: 0rem;
          @include responsive(tablet) {
               margin-top: 0rem;
               margin-left: 1rem;
          }
          @include responsive(mobile) {
               margin-top: 1rem;
               margin-left: 0rem;
          }
     }
}