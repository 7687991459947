.market {
  display: grid;
  grid-template-rows: $col-1;
  grid-template-columns: $col-1;
  transition: transform 250ms ease-in-out;
  box-shadow: 5px;
}

.curated-market {
  display: grid;
  grid-template-rows: 495px;
  grid-template-columns: 495px;
  transition: transform 250ms ease-in-out;
  box-shadow: 5px;
}

.featured {
  display: grid;
  grid-template-rows: 280px;
  transition: transform 250ms ease-in-out;
  @include responsive(ipadpro) {
    grid-template-rows: $col-1;
  }
  &:hover {
    transform: translateY(-5px);
  }
}

.featured:nth-child(1) {
  grid-template-rows: 790px;
  // grid-template-cl: 728px;
  grid-column: 1/3;
  grid-row: 1/3;
  @include responsive(foldable) {
    grid-template-rows: $col-1;
    grid-column: 1;
    grid-row: 1;
  }
}

.market-list-card:hover {
  transform: translateY(-10px);
}

.image-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}

.information-wrapper {
  height: 60px;
  display: grid;
  width: 100%;
  grid-gap: 8px;
  grid-template-columns: 45% 45%;
  .sticker {
    border-right: $neutrals-5 1px solid;
  }
  .sticker-icon {
    background-color: #c4c4c4;
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }
}
