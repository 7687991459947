.my-page {
  margin-top: 80px;
  @include responsive(tablet) {
    margin-top: 70px;
  }

  .my-page-menu {
    width: $col-4;
    display: flex;
    @include responsive(ipadpro) {
      width: $col-3;
      flex-direction: column;
    }
    @include responsive(tablet) {
      width: $col-2;
    }
    @include responsive(foldable) {
      width: $col-1;
    }
    .button-wrapper {
      width: $col-3;
      @include responsive(tablet) {
        width: $col-2;
      }
      @include responsive(foldable) {
        width: $col-1;
      }
      @include responsive(ipadpro) {
        overflow-x: scroll;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: $neutrals-4;
        }
      }
    }

    .my-page-sort {
      font-weight: 600;
      border: 1px solid $neutrals-5;
      border-radius: 25px;
      cursor: pointer;
    }
  }

  .my-page-grid {
    display: grid;
    grid-row-gap: 90px;
    grid-column-gap: 40px;
    grid-template-columns: repeat(4, 1fr);
    @include responsive(ipadpro) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include responsive(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include responsive(foldable) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .sticker-wrap {
    width: $col-4;
    @include responsive(ipadpro) {
      width: $col-3;
    }
    @include responsive(tablet) {
      width: $col-2;
    }
    @include responsive(foldable) {
      width: $col-1;
    }

    .round-sticker {
      width: $col-1;
      background: #ffffff;
      box-shadow: 5px;
    }
  }
}
