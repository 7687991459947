.user-board-list-item{
  overflow-x: auto;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;

  .user-board-list-item-font {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    text-overflow: ellipsis;
  }
}

.user-board-list-item-divider {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}