.statistics {
  border: 1px solid $neutrals-5;

  .statistics-infos {
    grid-template-columns: repeat(3, 1fr);

    .info-card {
      &.collection {
        animation-delay: 150ms;
      }
      &.nft {
        animation-delay: 300ms;
      }
      &.volume {
        animation-delay: 450ms;
      }
    }
  }
}
