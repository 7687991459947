.art-auth {
  max-width: $col-2;
  @include responsive(ipadpro) {
    width: 100%;
    margin-top: 120px;
  }

  .auth-wrapper {
    border: 1px solid $neutrals-5;
    padding: 64px;
    @include responsive(tablet) {
      padding: 24px;
    }
    .auth-infos {
      line-height: 2;
      .info {
        display: flex;
        @include responsive(tablet) {
          flex-direction: column;
        }
      }
      .label {
        width: 100px;
      }
      .editionWidth {
        width: calc(100% - 100px);
        border: 1px solid black;
        padding-left: 20px;
        padding-right: 16px;
      }
    }

    .auth-links {
      display: flex;
      margin-top: 96px;
      @include responsive(tablet) {
        flex-direction: column;
        margin-top: 32px;
      }
      .scan-link {
        @include responsive(tablet) {
          padding-bottom: 16px;
        }
      }
      img {
        width: 48px;
        height: 48px;
      }
    }

    .price-container {
      display: flex;
      margin-top: 48px;
      .price-content {
        margin-top: 48px;
        @include responsive(tablet) {
          margin-top: 32px;
        }
      }
      border-top: 1px solid $neutrals-5;
      @include responsive(tablet) {
        flex-direction: column;
        margin-top: 32px;
      }
    }
    .buy-btn-warpper {
      display: flex;
      @include responsive(mobile) {
        flex-direction: column;
      }

      .button {
        width: 49%;
        height: 64px;
        background: $primary;
        border-color: $primary;
        color: $white;
        &:hover {
          background: $primary-dark;
          border-color: $primary-dark;
          color: $white;
        }
        @include responsive(tablet) {
          margin-bottom: 16px;
        }
        @include responsive(mobile) {
          width: 100%;
        }
        &.wish-list {
          color: $primary;
          &.save {
            background-color: rgba(69,161,219,0.2);
            transition: 150ms background-color ease-in;
            
            &:hover {
              background-color: rgba(69,161,219,0.1);
            }
          }
          &.unsave {
            background-color: rgba(69,161,219,0.1);
            transition: 150ms background-color ease-in;
            
            &:hover {
              background-color: rgba(69,161,219,0.2);
            }
          }
        }
      }
    }
  }
}
