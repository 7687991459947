.curated-collection-card{
    .curated-collection-card-img{
        @include responsive(tablet){
            min-width: 222px;
            min-height: 222px;
        }
    }
    .curated-collection-card-desc-section{
        justify-content: center;

        .curated-collection-card-title{
            font-size: 30px;
            @include responsive(tablet) {
                font-size: 24px;
            }
        }
        .curated-collection-card-description{
            font-size: 16px;
            @include responsive(tablet) {
                font-size: 14px;
            }
        }
    }
}

.curated-collection-card + .curated-collection-card {
    margin-left: 16px;
}