.artist-page {
  margin-top: 80px;
  @include responsive(tablet) {
    margin-top: 70px;
  }

  .slider-section {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    background-color: rgba($color: $primary, $alpha: 0.05);
    @include responsive(ipadpro) {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }
    .slider-title {
      font-size: 36px;
      @include responsive(mobile) {
        font-size: 22px;
      }
    }
  }

  .artist-page-list-title {
    font-size: 36px;
    @include responsive(mobile) {
      font-size: 22px;
    }
  }
}
