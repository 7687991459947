.market-card-list-section {
  width: $col-4;
  @include responsive(ipadpro) {
    width: $col-3;
  }
  @include responsive(tablet) {
    width: $col-2;
  }
  @include responsive(foldable) {
    width: $col-1;
  }
  .market-card-list {
    display: grid;
    grid-gap: 32px;
    grid-row-gap: 96px;

    grid-template-columns: repeat(4, 1fr);
    @include responsive(ipadpro) {
      width: $col-3;
      grid-template-columns: repeat(3, 1fr);
    }
    @include responsive(tablet) {
      width: $col-2;
      grid-template-columns: repeat(2, 1fr);
    }
    @include responsive(foldable) {
      width: $col-1;
      grid-template-columns: repeat(1, 1fr);
    }

    .myNftList {
      position: relative;

      .delisting {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.6);

        & + button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 8px;
          background-color: $warning;
          color: #fff;
          font-size: 20px;
          border-color: $warning;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 200px;
          height: 44px;
        }
      }
    }
  }
  .market-card-list-grid {
    display: grid;
    grid-gap: 32px;
    grid-row-gap: 96px;
    width: $col-4;
    grid-template-columns: repeat(4, 1fr);
    @include responsive(ipadpro) {
      width: $col-2;
      grid-template-columns: repeat(2, 1fr);
      margin-right: auto;
      margin-left: auto;
    }
    @include responsive(tablet) {
      width: $col-2;
      grid-template-columns: repeat(2, 1fr);
    }
    @include responsive(foldable) {
      margin-top: 3rem;
      width: $col-1;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.artist-detail-market-card-list-section {
  // width: $col-3;
  // @include responsive(ipadpro) {
  //   width: $col-3;
  // }
  // @include responsive(tablet) {
  //   width: $col-2;
  // }
  // @include responsive(foldable) {
  //   width: $col-1;
  // }
  .artist-detail-market-card-list {
    display: grid;
    grid-gap: 32px;
    grid-row-gap: 96px;

    grid-template-columns: repeat(3, 1fr);
    @include responsive(ipadpro) {
      width: $col-3;
      grid-template-columns: repeat(3, 1fr);
    }
    @include responsive(tablet) {
      width: $col-2;
      grid-template-columns: repeat(2, 1fr);
    }
    @include responsive(foldable) {
      width: $col-1;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .artist-detail-market-card-list-grid {
    display: grid;
    grid-gap: 32px;
    grid-row-gap: 96px;
    width: $col-3;
    grid-template-columns: repeat(3, 1fr);
    @include responsive(ipadpro) {
      width: $col-2;
      grid-template-columns: repeat(2, 1fr);
      margin-right: auto;
      margin-left: auto;
    }
    @include responsive(tablet) {
      width: $col-2;
      grid-template-columns: repeat(2, 1fr);
    }
    @include responsive(foldable) {
      margin-top: 3rem;
      width: $col-1;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
