.news-sticker {
  transition: box-shadow 250ms ease-in-out, transform 250ms ease-in-out;
  // height: 132px;
  .sticker-content {
    z-index: 999;
    width: 265px;
    left: 20px;
    bottom: 20px;
  }
  &:hover {
    transform: scale(1.1);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  }
}
