.general-detail {
  max-width: $col-2;
  @include responsive(ipadpro) {
    top: 9rem;
  }
  .title {
    line-height: 1.1;
    font-size: 3.5rem;
    @include responsive(mobile) {
      font-size: 2.25rem;
    }
  }

  .description {
    line-height: 1.8;
  }

  .infos {
    line-height: 1.8;
  }

  .artist-info {
    border-top: 1px solid $neutrals-5;
  }

  .artist-info-samll {
    border-top: 1px solid $neutrals-5;
    max-width: 200px;
  }

  .posted-date {
    .icon {
      margin-top: -5px;
    }
  }
}

@include responsive(ipadpro) {
  .general-detail {
    width: 100%;
  }
}
