.exhibition-list-section {
  width:100%;
  margin-top: 65px;
  .exhibition-list {
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr;
    .round-sticker {
      transition: 250ms ease-in-out transform, 250ms ease-in-out box-shadow;
      box-shadow: 5px 5px 24px rgba(31, 30, 28, 0.05);
      &:hover {
        transform: translateY(-5px);
        box-shadow: 5px 10px 28px rgba(31, 30, 28, 0.1);
      }
    }
  }
}
