.popup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  align-items: center;
  z-index: 999;
}

.popup .overlap-wrapper {
  background-color: #ffffff;
  height: 175px;
  overflow: hidden;
  width: 400px;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .popup .overlap-wrapper {
    height: 280px;
  }
}

.popup .overlap {
  height: 143px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 6px;
  width: 100%;
}

.popup .overlap-group {
  height: 143px;
  left: 0;
  position: absolute;
  top: 0;
  width: 391px;
}

.popup .group {
  height: 43px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 93px;
}

.popup .overlap-group-wrapper {
  height: 133px;
  left: 270px;
  position: absolute;
  top: 10px;
  width: 153px;
}

.popup .div {
  height: 131px;
  left: -7px;
  position: relative;
  top: 1px;
  width: 166px;
}

.popup .overlap-group-2 {
  height: 131px;
  left: 0;
  position: absolute;
  top: 0;
  width: 166px;
}

.popup .ellipse {
  border: 1px solid;
  border-color: #c0c0c0;
  border-radius: 76.82px/32.95px;
  height: 66px;
  left: 6px;
  position: absolute;
  top: 33px;
  transform: rotate(-28.39deg);
  width: 154px;
}

.popup .ellipse-2 {
  border: 1px solid;
  border-color: #c0c0c0;
  border-radius: 63.06px/28.95px;
  height: 58px;
  left: 16px;
  position: absolute;
  top: 37px;
  transform: rotate(-28.39deg);
  width: 126px;
}

.popup .ellipse-3 {
  border: 1px solid;
  border-color: #c0c0c0;
  border-radius: 59.69px/28.34px;
  height: 57px;
  left: 22px;
  position: absolute;
  top: 36px;
  transform: rotate(-28.39deg);
  width: 119px;
}

.popup .ellipse-4 {
  background-color: #c0c0c0;
  border-radius: 1.37px;
  height: 3px;
  left: 42px;
  position: absolute;
  top: 60px;
  width: 3px;
}

.popup .ellipse-5 {
  background-color: #c0c0c0;
  border-radius: 1.72px;
  height: 3px;
  left: 14px;
  position: absolute;
  top: 91px;
  width: 3px;
}

.popup .ellipse-6 {
  background-color: #c0c0c0;
  border-radius: 1.37px;
  height: 3px;
  left: 90px;
  position: absolute;
  top: 89px;
  width: 3px;
}

.popup .ellipse-7 {
  background-color: #c0c0c0;
  border-radius: 1.03px;
  height: 2px;
  left: 94px;
  position: absolute;
  top: 24px;
  width: 2px;
}

.popup .img {
  height: 84px;
  left: 116px;
  position: absolute;
  top: 7px;
  width: 84px;
}

.popup .enter-universe {
  color: #202020;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 65px;
  position: absolute;
  top: 20px;
  display: flex;
  white-space: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 400px;
}

.popup .div-wrapper {
  height: 18px;
  left: 242px;
  position: absolute;
  top: 17px;
  width: 31px;
}

.popup .overlap-2 {
  height: 21px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 34px;
}

.popup .ellipse-8 {
  background-color: #c0c0c0;
  border-radius: 7.75px/7.79px;
  height: 16px;
  left: 9px;
  position: absolute;
  top: 2px;
  width: 15px;
}

.popup .ellipse-9 {
  border: 1px solid;
  border-color: #c0c0c0;
  border-radius: 16.17px/4.95px;
  height: 10px;
  left: 1px;
  position: absolute;
  top: 5px;
  transform: rotate(20.77deg);
  width: 32px;
}

.popup .group-2 {
  height: 89px;
  left: 8px;
  position: absolute;
  top: 7px;
  width: 300px;
}

.popup .ellipse-10 {
  background-color: #c0c0c0;
  border-radius: 1.5px;
  height: 3px;
  left: 0;
  position: absolute;
  top: 86px;
  width: 3px;
}

.popup .ellipse-11 {
  background-color: #c0c0c0;
  border-radius: 1.5px;
  height: 3px;
  left: 297px;
  position: absolute;
  top: 83px;
  width: 3px;
}

.popup .ellipse-12 {
  background-color: #c0c0c0;
  border-radius: 1.5px;
  height: 3px;
  left: 64px;
  position: absolute;
  top: 0;
  width: 3px;
}

.popup .ellipse-13 {
  background-color: #c0c0c0;
  border-radius: 1.5px;
  height: 3px;
  left: 116px;
  position: absolute;
  top: 31px;
  width: 3px;
}

.popup .ellipse-14 {
  background-color: #c0c0c0;
  border-radius: 1px;
  height: 2px;
  left: 47px;
  position: absolute;
  top: 58px;
  width: 2px;
}

.popup .ellipse-15 {
  background-color: #c0c0c0;
  border-radius: 2px;
  height: 4px;
  left: 291px;
  position: absolute;
  top: 20px;
  width: 4px;
}

.popup .ellipse-16 {
  height: 31px;
  left: 0;
  position: absolute;
  top: 53px;
  width: 20px;
}

.popup .token-staking-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #202020;
  border-radius: 23px;
  box-shadow: 0px 1px 5.7px 1px #94949426;
  height: 40px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .popup .token-staking-wrapper {
    width: 220px;
  }
}

.popup .token-staking {
  color: #202020;
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  font-weight: 700;
}

.popup .x-button {
  background-color: #202020;
  border-radius: 7.5px;
  height: 15px;
  right: 0;
  position: absolute;
  top: 0;
  width: 15px;
  cursor: pointer;
}

.popup .group-4 {
  height: 7px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 7px;
}

.popup .overlap-group-3 {
  height: 7px;
  position: relative;
}

.popup .rectangle {
  background-color: #ffffff;
  height: 9px;
  left: 3px;
  position: absolute;
  top: -1px;
  transform: rotate(-135deg);
  width: 1px;
}

.popup .rectangle-2 {
  background-color: #ffffff;
  height: 9px;
  left: 3px;
  position: absolute;
  top: -1px;
  transform: rotate(-45deg);
  width: 1px;
}

.popup .NFT-staking-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #202020;
  border-radius: 23px;
  box-shadow: 0px 1px 5.7px 1px #94949426;
  height: 40px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .popup .NFT-staking-wrapper {
    width: 220px;
  }
}

.popup .NFT-staking {
  color: #202020;
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
}

.overlap .staking {
  margin-top: 100px;
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

@media (max-width: 991px) {
  .overlap .staking {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 20px;
  }
}
