.main-title {
  font-size: 7.5rem;
  @include responsive(foldable) {
    font-size: 3.5rem;
    text-align: center;
  }
}

.notable {
  text-align: end;
  @include responsive(foldable) {
    text-align: center;
  }
}
