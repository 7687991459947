.notable-wrapper {
  width: 1630px;
  @include responsive(ipadpro) {
    width: $col-3;
  }
  @include responsive(tablet) {
    width: $col-2;
  }
  @include responsive(foldable) {
    width: $col-1;
  }
  .card-wrapper {
    height: 608px;
    width: 100%;
    .card-box:nth-child(5) {
      .card-col {
        border: none;
      }
    }
    .card-col {
      width: 326px;
      // height: 608px;
      border-right: 1px solid $neutrals-5;
      transition: transform 250ms ease-in-out, box-shadow 250ms ease-in-out, border-radius 250ms ease-in-out;
      .notable-card-image {
        border-radius: 50%;
        width: 244px;
        height: 244px;
      }
      &:hover {
        @include default-box-shadow;
        border-radius: 24px;
      }
    }
  }
}
