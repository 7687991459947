.exhibition-detail-page {
    .exhibition-detail-section {
        display: grid;
        gap: 86px;
        grid-template-columns: 1134px 1fr;
        @include responsive(tablet) {
            display: flex;
            flex-direction: column;
        }
        .exhibition-detail-main {
            border: 1px rgba(0,0,0,0.1) solid;
            border-radius: 20px;
            padding: 74px 117px;
            flex: 1;
            @include responsive(tablet) {
                border: none;
                border-radius: 0px;
                padding: 0px;
                height: 100%;
            }
            .exhibition-detail-title {
                font-size: 56px;
                @include responsive(tablet) {
                    font-size: 36px;
                }
            }
            .exhibition-created{
                margin-top: 16px;
                color: $neutrals-3;
            }
            .exhibition-description{
                padding-top: 64px;
            }
        }
    }
}