.main-page {
  .main-artist-section{
    .main-artist-section-title {
      color: $primary;
      font-size: 36px;
      @include responsive(tablet) {
        font-size: 22px;
      }
    }
    .main-artist-section-btn {
      font-size: 16px;
      @include responsive(tablet) {
        font-size: 14px;
      }
    }
    .main-artist-section-card-wrapper{
      margin-top: 72px;
      justify-content: center;
      @include responsive(tablet) {
        margin-top: 24px;
        justify-content: flex-start;
      }
      .main-artist-section-card {
        width: 300px;
        @include responsive(ipadpro) {
          width: 260px;
        }
        @include responsive(mobile) {
          width: 200px;
        }

        .main-artist-profile {
          display: flex;
          flex-direction: column;
          transition: all 250ms;
          @include responsive(mobile) {
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
          }
          .main-artist-profile-info{
            justify-content: center;
            align-items: center;
            @include responsive(mobile) {
              justify-content: center;
              align-items: flex-start;
            }
            .main-artist-profile-info-name{
              font-size: 16px;
              margin-top: 8px;
              @include responsive(mobile) {
                font-size: 15px;
                margin-top: 0px;
              }
            }
            .main-artist-profile-info-username{
              font-size: 16px;
              @include responsive(mobile) {
                font-size: 13px;
              }
            }
          }
          .main-artist-profile-img {
            width: 244px;
            height: 244px;
            margin-top: 32px;
            margin-right: 0px;
            @include responsive(mobile) {
              width: 64px;
              height: 64px;
              margin-top: 0px;
              margin-right: 12px;
            }
          }
        }
      
        .main-artist-profile:hover {
          transform: translateY(-5px);
        }
      }

      .main-artist-section-card + .main-artist-section-card {
        margin-top: 112px;
        @include responsive(tablet) {
          margin-top: 56px;
        }
        @include responsive(mobile) {
          margin-top: 32px;
        }
      }
    }
  }
  
  .featured-artist {
    .featured-artist-card{
      max-width: 364px;
      .featured-artist-card-image:hover {
        transform: translateY(-3px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
      }
    }
    .featured-artist-card + .featured-artist-card {
      margin-left: 32px;
    }
    .featured-artist-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-row-gap: 96px;
      grid-column-gap: 32px;
      width: 100%;
      @include responsive(ipadpro) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(foldable) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  
  .alarm-wrapper {
    margin-top: 72px;
    padding-top: 6rem;
    padding-bottom: 6rem;
    @include responsive(tablet) {
      padding-top: 5rem;
    }
    @include responsive(foldable) {
      margin-top: 32px;
      padding-top: 4rem;
    }
    @include responsive(mobile) {
      margin-top: 0px;
      padding-top: 3rem;
    }
    .alarm {
      box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.25);
      display: grid;
      gap: 16px;
      grid-template-rows: 50px;
      grid-template-columns: 20px 1fr;
      width: 1120px;
      max-width: 100%;
      height: 50px;
      
      .alarm-information {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 1fr 100px;

        .alarm-information-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .alarm-information-created-at {
          font-weight: 400; 
          line-height: 38px;
          font-size: 12px;
        }
      }
    }
  }

  .featured-art {
    margin-top: 13.5rem;
    @include responsive(foldable) {
      margin-top: 3rem;
    }
    .featured-art-title {
      font-size: 7.5rem;
      @include responsive(foldable) {
        font-size: 3.5rem;
        text-align: center;
      }
    }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 32px;
      width: $col-4;
      height: auto;
      margin-top: 6rem;
      @include responsive(ipadpro) {
        width: $col-2;
        grid-template-columns: repeat(2, 1fr);
        margin-right: auto;
        margin-left: auto;
      }
      @include responsive(tablet) {
        width: $col-2;
        grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(foldable) {
        margin-top: 3rem;
        width: $col-1;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .recent-nft {
    padding-top: 100px;
    @include responsive(mobile) {
      padding-top: 48px;
    }
    .recent-nft-title {
      color: $primary;
      font-size: 36px;
      @include responsive(tablet) {
        font-size: 22px;
      }
    }
    .recent-nft-btn {
      font-size: 16px;
      @include responsive(tablet) {
        font-size: 14px;
      }
    }
    .recent-nft-card-wrapper {
      padding-top: 4px;
      padding-bottom: 32px;
      .recent-nft-card{
        .recent-nft-pc-card{

          max-width: 284px;
          box-shadow: 0 24px 48px rgba(0, 0, 0, 0.05);
          min-height: 392px;
          transition: all 250ms;
          @include responsive(mobile) {
            display:none;
          }
        }
        .recent-nft-pc-card:hover {
          transform: translateY(-3px);
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
        }
        .recent-nft-mobile-card {
          display: none;
          transition: all 250ms;
          @include responsive(mobile) {
            display: flex;
          }
        }
        .recent-nft-mobile-card:hover {
          transform: translateY(-3px);
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
        }
      }
      
      .recent-nft-card + .recent-nft-card {
        margin-left: 32px;
        @include responsive(mobile) {
          margin-left: 12px;
        }
      }
    }
    .recent-nft-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-row-gap: 32px;
      grid-column-gap: 32px;
      @include responsive(tablet) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include responsive(foldable) {
        grid-template-columns: repeat(1, 1fr);
      }
      .recent-nft-item {
        box-shadow: 0 24px 48px rgba(0, 0, 0, 0.05);
        min-height: 392px;
      }
      .recent-nft-item:hover {
        transform: translateY(-3px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
      }
    }
  }

  .recent-exhibition {
    margin-top: 100px;
    @include responsive(mobile) {
      margin-top: 32px;
    }
    .recent-exhibition-title {
      color: $primary;
      font-size: 36px;
      @include responsive(tablet) {
        font-size: 22px;
      }
    }
    .recent-exhibition-btn {
      font-size: 16px;
      @include responsive(tablet) {
        font-size: 14px;
      }
    }
    .recent-exhibition-card-wrapper {
      .recent-exhibition-card{
        padding-top: 4px;
        width: 364px;
        @include responsive(mobile) {
          width: 160px;
        }
        .recent-exhibition-card-image {
          width: 364px;
          height: 364px;
          transition: all 250ms;
          @include responsive(mobile) {
            width: 160px;
            height: 160px;
          }
        }
        .recent-exhibition-card-image:hover {
          transform: translateY(-3px);
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
        }

        .recent-exhibition-card-title{
          font-size: 24px;
          margin-top: 32px;
          @include responsive(mobile) {
            margin-top: 20px;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .recent-exhibition-card-desc{
          font-size: 14px;
          @include responsive(mobile) {
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .recent-exhibition-card-created{
          display: flex;
          @include responsive(mobile) {
            display: none;
          }
        }
      }
      .recent-exhibition-card + .recent-exhibition-card {
        margin-left: 32px;
      }
    }
  }

  .main-user-board-wrapper {
    border: solid 1px $neutrals-5;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 108px;
    @include responsive(foldable) {
      border: solid 0px $neutrals-5;
      border-radius: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 48px;
    }
    .main-user-board {
      width: 100%;
      max-width: $col-3;
      .main-user-board-title {
        color: $primary;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        @include responsive(foldable) {
          font-size: 22px;
          justify-content: flex-start;
        }
      }
      .main-user-board-btn {
        font-size: 16px;
        @include responsive(foldable) {
          font-size: 14px;
        }
      }
    }
  }

  .partners {
    margin-top: 100px;
    @include responsive(mobile) {
      margin-top: 32px;
    }
    .partners-title {
      color: $primary;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      @include responsive(foldable) {
        font-size: 22px;
        justify-content: flex-start;
      }
    }
  }

  .deco-background {
    background-color: $neutrals-7;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 800px;
  }

  .recent-news-section {
    width: $col-3;
    @include responsive(tablet) {
      width: $col-2;
    }
    @include responsive(foldable) {
      width: $col-1;
    }
    .recent-news-head {
      display: flex;
      justify-content: space-between;
      @include responsive(mobile) {
        display: flex;
        flex-direction: column;
      }
    }
    .recent-news-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 96px;
      grid-column-gap: 30px;
      @include responsive(tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(foldable) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .view-more {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
