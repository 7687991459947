.title-section {
  padding-top: 120px;
  padding-bottom: 120px;
  @include responsive(foldable) {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .title {
    .title-text {
      font-size: 7.5rem;
      @include responsive(foldable) {
        font-size: 3.5rem;
      }
    }
  }
}
