.user-board-page-wrapper {
  .user-board-page {
    display: grid;
    gap: 86px;
    grid-template-columns: 1134px 1fr;
    @include responsive(tablet) {
      display: flex;
      flex-direction: column;
    }
    .user-board-contents-section {
      width: 100%;
      .user-board-detail-page {
        .user-board-detail-main {
          border: 1px rgba(0, 0, 0, 0.1) solid;
          border-radius: 20px;
          padding: 74px 117px;
          flex: 1;
          @include responsive(tablet) {
            border: none;
            border-radius: 0px;
            padding: 0px;
            height: 100%;
          }
          .user-board-title {
            font-size: 56px;
            @include responsive(tablet) {
              font-size: 36px;
            }
          }
          .user-board-created {
            margin-top: 16px;
            color: $neutrals-3;
          }
          .user-board-description {
            padding-top: 64px;
          }
        }
      }
    }
    .user-board-ads-section {
      @include responsive(tablet) {
        width: 100%;
      }
    }
  }
}

.board-write-image-container {
  .board-write-image-item + .board-write-image-item {
    margin-top: 6px;
  }
}

.user-board-search-wrapper {
  width: $col-2;
  max-width: 100%;
  .user-board-search-input {
    width: 500px;
    max-width: 70%;
    @include responsive(tablet) {
      width: 250px;
      max-width: 60%;
    }
  }
}

.user-board-write-wrapper {
  flex-direction: row;
  @include responsive(tablet) {
    flex-direction: column;
    width: 100%;
  }
  .user-board-write-btn {
    width: 354px;
    margin-left: 8px;
    margin-right: 8px;
    @include responsive(tablet) {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
