.nav-banner{
  height: 148px;
  background-color: grey;
}
.nav-wrapper {
  border-bottom: 1px $neutrals-5 solid;
  .nav {
    margin-right: auto;
    margin-left: auto;
    width: $col-4;
    @include responsive(ipadpro) {
      width: 90%;
    }

    .nav-inside {
      height: 80px;
      @include responsive(tablet) {
        height: 70px;
      }
    }
  
    .menu-list {
      @include responsive(tablet) {
        display: none !important;
      }
    }
  
    .menu-item + .menu-item {
      margin-left: 4rem;
    }
  
    .search-bar {
      z-index: 12;
      @include responsive(tablet) {
        display: none;
      }
  
      .icon-wrapper:hover .search {
        fill: #fff;
      }
      .icon {
        left: -0.75rem;
        transition: 150ms left ease-in-out, 150ms fill ease-in-out;
        fill: $neutrals-4;
  
        &.activate {
          left: -2rem; //-1.75rem
        }
      }
  
      .user-input {
        padding: 0;
        background-color: rgba(0, 0, 0, 0);
        color: $neutrals-1;
  
        transition: 150ms width ease-in;
  
        &.activate {
          width: 9rem; //9rem;
        }
      }
  
      .close {
        top: 2px;
        right: -34px;
        transition: 100ms opacity ease-in-out, 100ms right ease-in-out;
  
        &.activate {
          right: 0;
          opacity: 1;
        }
  
        .icon {
          fill: $neutrals-2;
        }
  
        &:hover .icon {
          fill: $neutrals-1;
        }
      }
  
      .search-popup {
        top: 2rem;
        right: 0;
        z-index: 14;
  
        &.activate {
          display: block;
        }
  
        .search-results {
          width: 600px;
          min-height: 40vh;
          max-height: 60vh;
  
          .category {
            top: 0;
          }
  
          .sticker-list {
            border-top: 1px solid $neutrals-5;
          }
        }
      }
    }
  
    .signup {
      border: $primary 1px solid;
      transition: 150ms border ease-out;
  
      &:hover {
        outline: $primary 2px solid;
      }
    }
  
    .logout {
      border: $neutrals-5 1px solid;
  
      &:hover {
        outline: $neutrals-2 1px solid;
      }
    }
  
    .my-profile {
      .my-profile-frame {
        width: 40px;
        height: 40px;
  
        &.opened {
          outline: $primary 1px solid;
        }
        .my-profile-img {
          width: 40px;
          height: 40px;
        }
      }
  
      .my-menu {
        width: 160px;
        left: 50%;
        top: 52px;
        transform: translateX(-50%);
        background: #f4f4f4;
        z-index: 10;
        border-radius: 15px;
  
        &:after {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: '';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(244, 244, 244, 0);
          border-bottom-color: #f4f4f4;
          border-width: 10px;
          margin-left: -10px;
        }
      }
    }
  
    .user-status {
      @include responsive(tablet) {
        display: none !important;
      }
    }
  
    .hamburger {
      display: none;
      @include responsive(tablet) {
        display: inline-block;
        border: none;
        background: none;
      }
      @include responsive(mobile) {
        position: absolute;
        z-index: 15;
        right: 5%;
        border: none;
        background: none;
      }
    }
  }
}

.activate-mobile {
  transform: translateX(-100%);
}

.mobile-menu-wrap {
  display: none;
  @include responsive(tablet) {
    display: block;
    position: fixed;
    height: 100%;
    right: -100%;
    z-index: 20;
    transition: transform 0.3s ease;
    background-color: $white;

    .mobile-menu-list-wrapper {
      // height: 120vh;
      // padding-top: 70px;
      .mobile-menu-nav-header {
        height: 80px;
        @include responsive(tablet) {
          height: 70px;
        }
      }

      .mobile-menu-list {
        font-size: 20px;
      }
      :hover {
        color: $primary;
      }
    }
  }

  .mobile-search-bar {
    z-index: 22;

    .user-input {
      padding-left: 20px;
      background-color: rgba(0, 0, 0, 0);
      color: $neutrals-1;

      transition: 150ms width ease-in;

      &.activate {
        width: 9rem; //9rem;
      }
    }

    .search-popup {
      top: 48px;
      left: 0;
      z-index: 24;

      &.activate {
        display: block;
      }

      .search-results {
        width: 600px;
        min-height: 40vh;
        max-height: 60vh;

        .category {
          top: 0;
        }

        .sticker-list {
          border-top: 1px solid $neutrals-5;
        }
      }
    }
  }
}

// intent://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=19e36309-0b0e-4c6c-9c75-57ccd06524d5#Intent;scheme=kakaotalk;package=com.kakao.talk;end