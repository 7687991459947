.market-page {
  margin-top: 80px;
  @include responsive(tablet) {
    margin-top: 70px;
  }
  .market-slider-pc-title {
    display: flex;
    @include responsive(mobile) {
      display: none;
    }
  }
  .market-slider-mobile-title {
    display: none;
    @include responsive(mobile) {
      display: flex;
    }
  }

  .market-slider-pc-featured {
    display: flex;
    @include responsive(tablet) {
      display: none;
    }
  }
  .market-slider-mobile-featured {
    display: none;
    @include responsive(tablet) {
      display: flex;
    }
  }

  .market-slider-section {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
    background-color: rgba($color: $primary, $alpha: 0.05);
    @include responsive(ipadpro) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  .list-wrap {
    overflow-y: visible;
    min-height: 600px;
    .list-btn-wrapp {
      display: flex;
      flex-direction: row;
      @include responsive(mobile) {
        flex-direction: column;
      }
      .sort-btn-wrapper {
        .sort-box {
          width: 292px;
          top: 100%;
          right: 0%;
          background-color: $neutrals-6;
          z-index: $component-zindex;
          border-radius: 12px;
          transition: background-color 0.5s;
        }
      }
      .filter-btn-wrapper {
        position: relative;
        .filter-btn {
          min-width: 80px;
          border-radius: 20px;
          @include responsive(foldable) {
            margin-top: 0.6rem;
          }
        }
        .activate {
          border: 1px solid $neutrals-1;
          border-radius: 20px 20px 0 0;
          border-bottom: none;
        }
        .filter-box {
          position: absolute;
          top: calc(100% - 16px);
          right: 0%;
          max-width: 611px;
          width: 100vw;
          border: 1px solid $neutrals-6;
          border-radius: 12px;
          background-color: #ffffff;
          z-index: $component-zindex;
          transition: background-color 0.5s;
          padding: 52px 48px;
          @include responsive(mobile) {
            padding: 24px 16px;
          }

          .filter-group + .filter-group {
            margin-top: 36px;
          }

          .filter-item + .filter-item {
            margin-left: 14px;
          }

          .filter-button {
            width: 235px;
            height: 56px;
            background-color: $neutrals-6;
            &.activate {
              background-color: $primary;
              border-width: 0px;
              border-radius: 0px;
              color: white;
            }
          }
        }
      }
      .inside-sort {
        cursor: pointer;
        border-radius: 10px;
        &:hover {
          background-color: lightgray;
        }
      }
    }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: $col-4;
      height: auto;
      grid-row-gap: 96px;
      grid-column-gap: 30px;
      @include responsive(ipadpro) {
        width: $col-3;
        grid-template-columns: repeat(3, 1fr);
      }
      @include responsive(tablet) {
        width: $col-2;
        grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(foldable) {
        margin-left: auto;
        margin-right: auto;
        width: $col-1;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
