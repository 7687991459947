.artist-profile {
  width: 244px;
  transition: 250ms ease-in-out transform;
  @include responsive(ipadpro) {
    width: 182px;
  }
  .artist-img {
    width: 244px;
    height: 244px;
    @include responsive(ipadpro) {
      width: 182px;
      height: 182px;
    }
    .heart-btn {
      width: 48px;
      height: 48px;
      right: 0;
      bottom: 25px;
      @include responsive(ipadpro) {
        right: 10px;
        bottom: 0;
      }
    }

    .like {
      background-color: $clicked-heart;
    }
  }
  .artist-img-text {
    top: 3rem;
    @include responsive(ipadpro) {
      top: 0.6rem;
    }
  }
  .profile-info {
    .social-btn {
      width: 64px;
    }
  }
}

.artist-slider-card {
  height: 600px;
  @include responsive(ipadpro) {
    height: $col-1;
  }
  .artist-slider-card-img {
    height: 372px;
    filter: brightness(1) blur(0);
    transition: 250ms ease-in-out filter;
    @include responsive(ipadpro) {
      height: 200px;
    }
  }

  .artist-slider-card-text {
    width: 351px;
    height: 250px;
    top: 0%;
    left: 14%;
    transition: 250ms ease-in-out opacity;
    @include responsive(ipadpro) {
      width: $col-1;
      height: 299px;
      top: -45%;
      left: 0%;
    }
  }

  .absolute-center {
    left: 122px;
    top: 244px;
    @include responsive(ipadpro) {
      top: 30%;
      left: 25%;
    }
  }

  &:hover .artist-profile {
    transform: translateY(-5px);
  }

  &:hover .artist-slider-card-img {
    filter: brightness(0.25) blur(3px);
  }

  &:hover .artist-slider-card-text {
    opacity: 0.95;
  }
}

.artist-slider-card-new {
  height: 328px;
  @include responsive(ipadpro) {
    height: auto;
    width: auto;
  }

  .artist-profile {
    display: flex;
    flex-direction: column;
    @include responsive(mobile) {
      flex-direction: column-reverse;
    }
    .artist-name{
      font-size: 20px;
      margin-top: 0px;
      @include responsive(mobile) {
        font-size: 15px;
        margin-top: 12px;
      }
    }
    .artist-desc{
      margin-top: 8px;
      font-size: 16px;
      @include responsive(mobile) {
        margin-top: 0px;
        font-size: 13px;
      }
    }
    .artist-img{
      margin-top: 32px;
      width: 244px;
      height: 244px;
      @include responsive(mobile) {
        width: 64px;
        height: 64px;
        margin-top: 0px;
      }
    }
  }

  &:hover .artist-profile {
    transform: translateY(-5px);
  }

  &:hover .artist-slider-card-img {
    filter: brightness(0.25) blur(3px);
  }

  &:hover .artist-slider-card-text {
    opacity: 0.95;
  }
}
