.signup-page {
  top: 0;
  bottom: 0;
  .signup-nav {
    height: 56px;
  }

  .signup-form {
    min-height: calc(100vh - 216px);
    .form-inner {
      width: 520px;
      @include responsive(foldable) {
        width: $mobile-width;
      }
      .content {
        &.register-form {
          animation-delay: 600ms;
        }

        .input-section {
          .label {
            width: 300px;
            @include responsive(foldable) {
              width: 160px;
            }
          }
          .status-icon {
            width: 16px;
            height: 16px;
          }

          .viewPassword {
            width: 24px;
            height: 24px;
          }

          .continue-botton {
            width: 72px;
            height: 72px;
            right: 0px;
            top: 0px;
            font-size: 12px;
            @include responsive(foldable) {
              width: 50px;
              height: 50px;
            }
          }
          input:focus {
            outline: none;
          }
        }

        .message-block .case {
          height: 44px;
        }
        li:hover {
          box-shadow: 0 0 11px rgba(100, 100, 100, 0.4);
        }
      }
    }
  }
}
