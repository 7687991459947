.search-container {
  padding-top: 64px;
  padding-bottom: 64px;
  @include responsive(tablet) {
      padding-top: 0px;
      padding-bottom: 48px;
 }
}

.ant-select .ant-select-selector {
  border-radius: 12px;
  height: 50px;
}
