.main {
  display: flex;
  padding-top: 47px;
  flex-direction: column;
}
.div-2 {
  display: flex;
  margin-top: 47px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-left: 47px;
}
@media (max-width: 991px) {
  .div-2 {
    max-width: 100%;
    margin-top: 40px;
    padding-left: 0;
  }
}
.div-3 {
  width: 100%;
  max-width: 1832px;
}
@media (max-width: 991px) {
  .div-3 {
    max-width: 100%;
  }
}
.div-4 {
  gap: 20px;
  display: flex;
}
@media (max-width: 991px) {
  .div-4 {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}
.column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 67%;
  margin-left: 0px;
}
@media (max-width: 991px) {
  .column {
    width: 100%;
  }
}
.div-5 {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
@media (max-width: 991px) {
  .div-5 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.img {
  aspect-ratio: 3.99;
  object-fit: contain;
  object-position: center;
  width: 618px;
  overflow: hidden;
  align-self: end;
  max-width: 100%;
}
.div-6 {
  color: #212121;
  align-self: stretch;
  margin-top: 44px;
  font-size: 20px;
  font-family: 500;
}
@media (max-width: 991px) {
  .div-6 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.div-7 {
  color: #212121;
  white-space: nowrap;
  border-radius: 30px;
  border: 2px solid #212121;
  background-color: #fff;
  align-self: start;
  margin-top: 46px;
  justify-content: center;
  padding: 10px 19px;
  font-family: 700;
  font-size: 18px;
}
@media (max-width: 991px) {
  .div-7 {
    white-space: initial;
    margin-top: 40px;
  }
}
.div-8 {
  align-self: start;
  display: flex;
  margin-top: 12px;
  gap: 15px;
}
.div-9 {
  border-radius: 8px;
  background-color: #030405;
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44px;
  flex: 1;
  padding: 0 4px;
}
.img-2 {
  aspect-ratio: 0.95;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
}
.div-10 {
  border-radius: 8px;
  background-color: #000;
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44px;
  flex: 1;
  padding: 0 2px;
}
.img-3 {
  aspect-ratio: 1.21;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
}
.div-11 {
  border-radius: 8px;
  background-color: #000;
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44px;
  flex: 1;
  padding: 0 3px;
}
.img-4 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
}
.column-2 {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .column-2 {
    width: 100%;
    margin-top: -400px;
    z-index: -1;
  }
}
.img-5 {
  aspect-ratio: 1.24;
  object-fit: contain;
  object-position: center;
  width: 100%;
  fill: #e7e7e7;
  overflow: hidden;
  margin-top: 10px;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .img-5 {
    max-width: 100%;
    margin-top: 40px;
  }
}
.img-6 {
  aspect-ratio: 960;
  object-fit: contain;
  object-position: center;
  width: 100%;
  stroke-width: 2px;
  stroke: #212121;
  overflow: hidden;
  align-self: stretch;
  margin-top: 18px;
}
@media (max-width: 991px) {
  .img-6 {
    max-width: 100%;
  }
}

.overlay {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 991px) {
  .overlay {
    display: flex;
    flex-direction: column;
  }
  .overlay img {
    display: none;
  }

  .overlay hr {
    width: 100%;
      margin: 10px 0;
      border: none;
      height: 1px;
      background-color: black;
  }
}

.overlay-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.overlay-container span {
  font-size: 50px;
  font-weight: 800;
}

.overlay-container .link {
  width: 50%;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 10px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: black;
}
@media (max-width: 991px) {
  .overlay-container .link {
    display: none;
  }
}

.inquiries {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  width: 100%;
  margin-top: 50px;
  text-align: left;
}

@media (max-width: 991px) {
  .inquiries {
    border-top: 2px solid black;
    border-left: none;
    border-bottom: none;
  }
}

.inquiries a {
  text-align: left;
  padding: 20px 10px;
  justify-content: center;
  font-size: 25px;
  font-family: 600;
  color: #000;
}
