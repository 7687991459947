.profile-card-list-section {
  // width: $col-4;
  // @include responsive(ipadpro) {
  //   width: $col-3;
  // }
  // @include responsive(tablet) {
  //   width: $col-2;
  //   text-align: center;
  // }
  // @include responsive(foldable) {
  //   width: $col-1;
  // }
  margin-top: 120px;
  @include responsive(mobile) {
    margin-top: 48px;
  }
  .profile-card-list {
    margin-top: 56px;
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(4, 1fr);
    @include responsive(ipadpro) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include responsive(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include responsive(mobile) {
      margin-top: 32px;
    }

    .round-sticker {
      // width: $col-1;
      transition: 250ms ease-in-out transform, 250ms ease-in-out box-shadow;
      box-shadow: 5px 5px 24px rgba(31, 30, 28, 0.05);
      &:hover {
        transform: translateY(-5px);
        box-shadow: 5px 10px 28px rgba(31, 30, 28, 0.1);
      }
    }
  }
}
