.background-gallery {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;

  .pos1 {
    left: 1%;
    top: 284px;
    animation-delay: 100ms;
  }

  .pos2 {
    right: 32%;
    top: 260px;
    animation-delay: 200ms;
    @include responsive(tablet) {
      display: none;
    }
  }

  .pos3 {
    left: 28%;
    bottom: 18%;
    animation-delay: 400ms;
    @include responsive(tablet) {
      display: none;
    }
  }

  .pos4 {
    left: 47%;
    top: -29px;
    animation-delay: 100ms;
  }

  .pos5 {
    left: 36%;
    top: 418px;
    animation-delay: 300ms;
    @include responsive(foldable) {
      display: none;
    }
  }

  .pos6 {
    right: 1%;
    top: 478px;
    animation-delay: 200ms;
    @include responsive(tablet) {
      display: none;
    }
  }

  .pos7 {
    right: 14%;
    top: 190px;
    animation-delay: 0ms;
  }

  .pos8 {
    right: 18%;
    bottom: 6%;
  }
}
