.index {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  align-items: center;
  z-index: 999;
}

.index .div {
  background-color: #ffffff;
  height: 350px;
  overflow: hidden;
  position: relative;
  width: 320px;
  border-radius: 10px;
}

.index .overlap {
  height: 229px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 405px;
}

.index .overlap-group {
  height: 226px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 405px;
}

.index .group {
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 108px;
}

.index .overlap-wrapper {
  height: 194px;
  left: 182px;
  position: absolute;
  top: 32px;
  width: 223px;
}

.index .overlap-2 {
  height: 190px;
  left: -9px;
  position: relative;
  top: 2px;
  width: 241px;
}

.index .overlap-group-2 {
  height: 190px;
  left: 0;
  position: absolute;
  top: 0;
  width: 241px;
}

.index .ellipse {
  border: 1px solid;
  border-color: #c0c0c0;
  border-radius: 111.49px/47.56px;
  height: 95px;
  left: 9px;
  position: absolute;
  top: 47px;
  transform: rotate(-28.39deg);
  width: 223px;
}

.index .ellipse-2 {
  border: 1px solid;
  border-color: #c0c0c0;
  border-radius: 91.45px/41.74px;
  height: 83px;
  left: 23px;
  position: absolute;
  top: 53px;
  transform: rotate(-28.39deg);
  width: 183px;
}

.index .ellipse-3 {
  border: 1px solid;
  border-color: #c0c0c0;
  border-radius: 86.53px/40.84px;
  height: 82px;
  left: 32px;
  position: absolute;
  top: 53px;
  transform: rotate(-28.39deg);
  width: 173px;
}

.index .ellipse-4 {
  background-color: #c0c0c0;
  border-radius: 2px;
  height: 4px;
  left: 60px;
  position: absolute;
  top: 87px;
  width: 4px;
}

.index .ellipse-5 {
  background-color: #c0c0c0;
  border-radius: 2.5px;
  height: 5px;
  left: 20px;
  position: absolute;
  top: 132px;
  width: 5px;
}

.index .ellipse-6 {
  background-color: #c0c0c0;
  border-radius: 2px;
  height: 4px;
  left: 130px;
  position: absolute;
  top: 129px;
  width: 4px;
}

.index .ellipse-7 {
  background-color: #c0c0c0;
  border-radius: 1.5px;
  height: 3px;
  left: 136px;
  position: absolute;
  top: 35px;
  width: 3px;
}

.index .img {
  height: 84px;
  left: 118px;
  position: absolute;
  top: 30px;
  width: 84px;
}

.index .enter-universe {
  color: #202020;
  font-family: "Inter", Helvetica;
  font-size: 32px;
  font-weight: 800;
  // left: 20px;
  letter-spacing: 0;
  line-height: 65px;
  position: absolute;
  top: 40px;
  white-space: nowrap;
}

.index .overlap-group-wrapper {
  height: 26px;
  left: 219px;
  position: absolute;
  top: 10px;
  width: 45px;
}

.index .overlap-3 {
  height: 29px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 47px;
}

.index .ellipse-8 {
  background-color: #c0c0c0;
  border-radius: 11.14px/11.19px;
  height: 22px;
  left: 12px;
  position: absolute;
  top: 3px;
  width: 22px;
}

.index .ellipse-9 {
  border: 1px solid;
  border-color: #c0c0c0;
  border-radius: 22.8px/6.67px;
  height: 13px;
  left: 1px;
  position: absolute;
  top: 8px;
  transform: rotate(20.77deg);
  width: 46px;
}

.index .group-2 {
  height: 153px;
  left: 54px;
  position: absolute;
  top: 2px;
  width: 213px;
}

.index .ellipse-10 {
  background-color: #c0c0c0;
  border-radius: 1.76px;
  height: 4px;
  left: 0;
  position: absolute;
  top: 115px;
  width: 4px;
}

.index .ellipse-11 {
  background-color: #c0c0c0;
  border-radius: 1.76px;
  height: 4px;
  left: 29px;
  position: absolute;
  top: 0;
  width: 4px;
}

.index .ellipse-12 {
  background-color: #c0c0c0;
  border-radius: 1.76px;
  height: 4px;
  left: 71px;
  position: absolute;
  top: 52px;
  width: 4px;
}

.index .ellipse-13 {
  background-color: #c0c0c0;
  border-radius: 1.17px;
  height: 2px;
  left: 59px;
  position: absolute;
  top: 151px;
  width: 2px;
}

.index .ellipse-14 {
  background-color: #c0c0c0;
  border-radius: 2.34px;
  height: 5px;
  left: 208px;
  position: absolute;
  top: 50px;
  width: 5px;
}

.index .group-3 {
  height: 81px;
  left: 48px;
  position: absolute;
  top: 144px;
  width: 222px;
}

.index .ellipse-15 {
  height: 31px;
  left: 0;
  position: absolute;
  top: 115px;
  width: 18px;
}

.index .x-button {
  background-color: #3d3d3d;
  border-radius: 7.5px;
  height: 15px;
  left: 296px;
  position: absolute;
  top: 0;
  width: 15px;
}

.index .div-wrapper {
  height: 7px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 7px;
}

.index .overlap-group-3 {
  height: 7px;
  position: relative;
}

.index .rectangle {
  background-color: #ffffff;
  height: 9px;
  left: 3px;
  position: absolute;
  top: -1px;
  transform: rotate(-135deg);
  width: 1px;
}

.index .rectangle-2 {
  background-color: #ffffff;
  height: 9px;
  left: 3px;
  position: absolute;
  top: -1px;
  transform: rotate(-45deg);
  width: 1px;
}

// .index .group-4 {
//   height: 81px;
//   left: 50px;
//   position: absolute;
//   top: 243px;
//   width: 220px;
// }
