// Main Banner Carousel

.banner-carousel {
  // margin-top: 96px;
  // width: 1200px;
  height: 100%;
  @include responsive(mobile) {
    // margin-top: 32px;
    // height: 218px;
  }

  .container {
    // max-width: $col-4;
    width: 100%;
    margin-bottom: 0;
    .carousel-card {
      // border-radius: 24px;
      flex:1;
      justify-content: center;
      align-items: center;
    }
  }
  .indicator-wrapper {
    bottom: 0;
  }
  .main-indicator-wrapper {
    bottom: 0;
    right : 0;
    transform: translateY(100px);
    @include responsive(tablet) {
      transform: translateY(80px);
    }
  }
}

// Basic Carousel

.multiple-view-carousel {
  height: auto;
  margin-top: 6rem;
  padding-top: 1rem;
  @include responsive(foldable) {
    margin-top: 3rem;
  }
  .container {
    width: 20%;
    @include responsive(ipadpro) {
      width: $col-1;
    }
    @include responsive(tablet) {
      // width: 380px;
      // margin-right: 360px;
    }
    @include responsive(foldable) {
      width: 50%;
      margin-right: 0;
    }
    @include responsive(mobile) {
      width: 58%;
    }
  }
  .container-1 {
    width: calc(100% /1);
  }
  .container-2 {
    width: calc(100% /2);
  }
  .container-3 {
    width: calc(100% /3);
  }
  .container-4 {
    width: calc(100% /4);
  }
  .container-5 {
    width: calc(100% /5);
  }
  .mini-container {
    width: 380px;
    @include responsive(ipadpro) {
      width: $col-1;
    }
    @include responsive(tablet) {
      // width: 380px;
      margin-right: 360px;
    }
    @include responsive(foldable) {
      margin-right: 0;
    }
  }
  .carousel-list {
    padding-left: 16px;
    padding-right: 16px;
    @include responsive(foldable) {
      padding-left: 0;
      padding-right: 0;
    }
    .carousel-item + .carousel-item {
      margin-top: 126px;
    }

    .mini-slide-img {
      height: 348px;
      @include responsive(ipadpro) {
        height: 332px;
      }
      @include responsive(foldable) {
        width: $col-1;
        height: $col-1;
      }
    }
    .slide-img {
      // width: $col-1;
      height: 488px;
      @include responsive(ipadpro) {
        height: 332px;
      }
      @include responsive(foldable) {
        width: $col-1;
        height: $col-1;
      }
    }
  }
  .highlight-box {
    box-shadow: 0px 10px 30px rgba(65, 25, 169, 0.1);
  }
}
