.create-page {
  margin-top: 80px;
  @include responsive(tablet) {
    margin-top: 70px;
  }
  .create-wrapper {
    max-width: 918px;
    padding-left: 2.7rem;
    padding-right: 2.7rem;
    background-color: white; //   height: 100px;
    .chian-wrapper {
      grid-template-columns: repeat(2, minmax(180px, 1fr));

      @include responsive(tablet) {
        grid-template-columns: repeat(1, minmax(180px, 1fr));
      }
      grid-auto-rows: auto;
      gap: 16px;
      .select-chain {
        min-height: 400px;
        &:hover {
          transform: translateY(-5px);
          box-shadow: 5px 5px 5px rgba(110, 56, 56, 0.4);
        }
      }
    }

    .upload-button {
      border-color: transparent;
      height: 40px;
      width: 160px;
      transition: all 0.12s ease-in-out 0s;
      transform-origin: center center;
    }
    .nft-form-box {
      flex: 1 1 auto;
      .form-input {
        margin-top: 2.7rem;
      }
      .form-input:first-child {
        margin-top: 5.4rem;
      }

      .upload-img-box {
        height: 402px;
        border: 2px dashed $neutrals-5;
        min-height: 150px;
        @include responsive(mobile) {
          height: 300px;
          padding: 10px;
        }
        .close-icon {
          top: 4%;
          right: 4%;
          width: 40px;
          height: 40px;
          @include responsive(mobile) {
            width: 24px;
            height: 24px;
          }
        }
        .upload-img {
          max-width: 320px;
          max-height: 320px;
          @include responsive(mobile) {
            max-width: 240px;
          }
          @include responsive(320px) {
            max-width: 200px;
          }
        }
        .img-input {
          position: fixed;
          left: -100vw;
        }
      }

      .price-input-box {
        min-height: 48px;
        transition: all 0.12s ease-in-out 0s;

        .arrow {
          bottom: -5px;
        }
      }
    }
    .nft-preview-box {
      flex-basis: 255px;
      top: 90px;
      @include responsive(foldable) {
        display: none;
      }
      .nft-preview-inner-box {
        height: 384px;
        .nft-preview-img-warapper {
          .nft-preview-img {
            width: 210px;
            height: 210px;
          }
        }
      }
    }
  }
}
