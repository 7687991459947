.news-slide-long-card {
  height: 496px;
  .news-slider-img {
    height: auto;
    width: 496px;
  }
  .card-content {
    width: 1056px;
    .news-content {
      width: $col-2;

      .news-slider-navigate {
        width: 105px;
        height: 40px;
      }
    }
  }
}
