.typing-demo {
  width: 35ch;
  animation: loading-typing 4s steps(37) infinite;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2em;
}

@keyframes loading-typing {
  from {
    width: 0;
  }
}
@include responsive(tablet) {
  .typing-demo {
    width: 34ch;
    font-size: 1rem;

    @include responsive(foldable) {
      width: 34ch;
      font-size: 0.8rem;
    }
  }
}
