.ant-btn[disabled] {
  background-color: $neutrals-4 !important;
  border-color: $neutrals-4 !important;
  color: $white;
}

.ant-btn:hover {
  background: $primary-2;
  border-color: $primary-2;
  color: $white;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  background: $primary;
  border-color: $primary;
  color: $white;
}
.ant-input {
  padding: 0;
  font-size: 16px;
}
.ant-input:focus {
  border: none;
  box-shadow: none;
}
.ant-input::placeholder {
  color: $neutrals-2;
}
textarea.ant-input {
  min-height: 19px;
}
