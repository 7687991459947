.image-container {
  transition: all 250ms;
  .card-img {
    width: 100%;
    height: 100%;
  }
  .audio-icon {
    right: 75px;
    top: 24px;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .video-icon {
    right: 18px;
    top: 24px;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .icon-img {
    width: 24px;
    height: 24px;
  }
}
