.art-detail {
  margin-top: 80px;
  @include responsive(tablet) {
    margin-top: 70px;
  }

  .art-media {
    max-width: 600px;
    max-height: 600px;
    @include responsive(foldable) {
      width: $col-1;
      height: $col-1;
    }
  }

  .full-size {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -9999px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0s;
    &.fade-in {
      left: 0;
      right: 0;
      z-index: 1;
      opacity: 1;
    }
    img {
      width: 80%;
      height: 80%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .main-section {
    margin-top: 9rem;
    .detail-infos {
      max-width: $col-4;
      grid-template-columns: repeat(2, 1fr);
      display: flex;
      justify-content: space-between;
      @include responsive(ipadpro) {
        flex-direction: column;
        align-items: center;
        @include padded-horizontal-300();
      }
      @include responsive(foldable) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .item-history {
    max-width: $col-4;
    @include responsive(ipadpro) {
      max-width: $col-2;
    }
    @include responsive(mobile) {
      @include padded-horizontal-300;
    }
    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background: $neutrals-4;
    }
    .item {
      @include responsive(foldable) {
        overflow-x: scroll;
      }
    }
    .history-list {
      padding-left: 3.9rem;
      padding-right: 3.9rem;
      @include responsive(mobile) {
        padding-left: 1.8rem;
        padding-right: 3.9rem;
      }
    }
    .flexible-box {
      flex-basis: 300px;
      @include responsive(ipadpro) {
        flex-basis: 150px;
      }
      @include responsive(tablet) {
        flex-basis: 120px;
        padding-right: 8px;
      }
    }
  }
}
