@import '/src/styles/mixins';
@import '/src/styles/constants';

.footer-wrapper {
  border-top: $neutrals-5 1px solid;
  .footer {
    // margin-right: auto;
    // margin-left: auto;
    // width: $col-4;
    // @include responsive(ipadpro) {
    //   width: $col-3;
    // }
    // @include responsive(tablet) {
    //   width: $col-2;
    // }
    // @include responsive(foldable) {
    //   width: $col-1;
    // }
    .content {
      height: 80px;
      @include responsive(foldable) {
        flex-wrap: wrap;
      }
      .menus {
        display: flex;
        align-items: center;
        @include responsive(foldable) {
          flex-direction: column;
          align-items: baseline;
        }
        .guideline {
          margin-left: 3rem;
          @include responsive(foldable) {
            margin-top: 0.5rem;
            margin-left: 0;
          }
        }
      }
    }

    .menu-list {
      margin-bottom: 0;
    }

    .logo {
      margin-top: 5px;
    }

    .menu-item + .menu-item::before {
      color: $neutrals-3;
      content: '|';
      padding-right: 1rem;
      padding-left: 1rem;
      @include responsive(mobile) {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
  
      }
    }
    .icon + .icon::before {
      content: ' ';
      padding-right: 0.7rem;
      padding-left: 0.7rem;
    }

    .icon-group {
      margin-top: auto;
      margin-bottom: auto;
      @include responsive(foldable) {
        margin-top: 0.5rem;
      }
    }
  }
}