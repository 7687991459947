html,
body {
  // 기본 font-size값 변경 제어
  -webkit-text-size-adjust: 100%; /*Chrome, Safari, newer versions of Opera*/
  -moz-text-size-adjust: 100%; /*Firefox*/
  -ms-text-size-adjust: 100%; /*Ie*/
  -o-text-size-adjust: 100%; /*old versions of Opera*/
  text-size-adjust: 100%;

  position: relative;
  margin: 0;
  padding: 0;
  font-size: 16px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  // background: #fce95e;
  overscroll-behavior-y: none;
  scroll-behavior: smooth;
  color: $neutrals-1;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

* {
  flex-shrink: 0;
}

.link {
  cursor: pointer;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.mobile-hidden {
  @include responsive(tablet) {
    display: none;
  }
}

.underlined-link {
  text-decoration: underline;
  cursor: pointer;
}

.top-140x {
  margin-top: 140px;
}

@for $i from 0 through 1200 {
  .top-#{$i * 4} {
    margin-top: #{$i * 0.3}rem;
  }

  .right-#{$i * 4} {
    margin-right: #{$i * 0.3}rem;
  }

  .bottom-#{$i * 4} {
    margin-bottom: #{$i * 0.3}rem;
  }

  .left-#{$i * 4} {
    margin-left: #{$i * 0.3}rem;
  }

  .margin-row-#{$i * 4} {
    margin-left: #{$i * 0.3}rem;
    margin-right: #{$i * 0.3}rem;
  }

  .margin-col-#{$i * 4} {
    margin-top: #{$i * 0.3}rem;
    margin-bottom: #{$i * 0.3}rem;
  }

  .margin-row-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-col-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .padding-#{$i * 4} {
    padding: #{$i * 0.3}rem;
  }

  .padding-left-#{$i * 4} {
    padding-left: #{$i * 0.3}rem;
  }

  .padding-right-#{$i * 4} {
    padding-right: #{$i * 0.3}rem;
  }

  .padding-top-#{$i * 4} {
    padding-top: #{$i * 0.3}rem;
  }

  .padding-bottom-#{$i * 4} {
    padding-bottom: #{$i * 0.3}rem;
  }

  .padding-row-#{$i * 4} {
    padding-left: #{$i * 0.3}rem;
    padding-right: #{$i * 0.3}rem;
  }

  .padding-col-#{$i * 4} {
    padding-top: #{$i * 0.3}rem;
    padding-bottom: #{$i * 0.3}rem;
  }

  .width-#{$i * 2} {
    width: #{$i * 0.125}rem;
  }

  .height-#{$i * 2} {
    height: #{$i * 0.125}rem;
  }

  .font-#{$i * 2} {
    font-size: #{$i * 0.125}rem;
  }
}

.width-col-3 {
  width: $col-3;
}

.half-width {
  width: 50%;
}

.max-width {
  width: 100%;
}

.full {
  width: 100%;
  height: 100%;
}

.viewport-max-width {
  width: 100vw;
}

.cap-max-width {
  max-width: 100%;
}

.auto-height {
  height: auto;
}

.max-height {
  height: 100%;
}

.padded-top {
  padding-top: 40px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
}

.fixed {
  position: fixed;
  left: 0;
  top: 0;
}

.square {
  width: 100%;
  padding-bottom: 100%;
}

.padded-horizontal {
  padding-left: calc((100% - 1200px) / 2);
  padding-right: calc((100% - 1200px) / 2);

  @media only screen and (max-width: $col-2) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.padded-horizontal-800 {
  padding-left: calc((100% - 800px) / 2);
  padding-right: calc((100% - 800px) / 2);

  @media only screen and (max-width: $col-2) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.padded-horizontal-300 {
  padding-left: calc(300px / 2);
  padding-right: calc(300px / 2);

  @media only screen and (max-width: $col-2) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.margin-left {
  margin-left: calc((100% - 1120px) / 2);
  @media only screen and (max-width: $col-2) {
    margin-left: 2.5rem;
  }
}

.margin-center {
  margin-right: auto;
  margin-left: auto;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scrollbar {
  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-thumb {
    height: 10%;
    border-radius: 10px;
    background: $neutrals-4;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
}

.outline-none {
  outline: none;
}

.border-none {
  border: none;
}

.bordered {
  border: solid 2px $primary;
}

.bordered-black {
  border: solid 1px $neutrals-1;
}

.bordered-red {
  border: solid 2px $secondary-red;
}

.light-bordered {
  border: solid 1px $primary;
}

.bordered-light-grey {
  border: solid 1px $neutrals-2;
}

.bordered-line-light-grey {
  border: solid 1px $neutrals-5;
}

.bordered-line-placeholder {
  border: solid 1px $placeholder;
}

.bordred-create {
  border: 1px solid rgba(0, 0, 0, 0.9);
}

.border-top {
  border-top: solid 1px $neutrals-5;
}

.border-bottom {
  border-bottom: solid 1px $neutrals-5;
}

.border-bottom-thicker {
  border-bottom: solid 2px $neutrals-5;
}

.border-50 {
  border-radius: 50%;
}

.divider {
  border-bottom: solid 1px #000000;
}

.divider-vertical {
  border-right: solid 1px #000000;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.background-center {
  background-position: center;
}

.background-contain {
  background-size: contain;
}

.background-cover {
  background-size: cover;
}

.rotate-180 {
  transform: rotate(180deg);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@for $i from 0 through 80 {
  .line-height-#{$i} {
    line-height: #{$i}px;
  }
}

@for $i from 0 through 52 {
  .border-radius-#{$i} {
    border-radius: #{$i}px;
  }
}

@for $i from 0 through 52 {
  .important-border-radius-#{$i} {
    border-radius: #{$i}px !important;
  }
}

@for $i from 0 through 52 {
  .border-top-left-radius-#{$i} {
    border-top-left-radius: #{$i}px;
  }
}

@for $i from 0 through 52 {
  .border-top-right-radius-#{$i} {
    border-top-right-radius: #{$i}px;
  }
}

@for $i from 0 through 52 {
  .border-bottom-right-radius-#{$i} {
    border-bottom-right-radius: #{$i}px;
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.box-shadow {
  box-shadow: rgba(100, 100, 100, 0.2) 0px 1px 10px 0px;
}

.background-none {
  background-color: transparent;
}

.background-white {
  background-color: #ffffff;
}

.background-primary {
  background-color: $primary;
}

.background-secondary {
  background-color: $secondary;
}

.background-neutrals-2 {
  background-color: $neutrals-2;
}

.background-neutrals-4 {
  background-color: $neutrals-4;
}

.background-neutrals-5 {
  background-color: $neutrals-5;
}

.background-neutrals-6 {
  background-color: $neutrals-6;
}

.background-neutrals-7 {
  background-color: $neutrals-7;
}

.background-featured-background {
  background-color: $featured-background;
}

.background-kakao {
  background-color: $kakao-yellow;
}

.background-user-board-list-title {
  background-color: $user-board-list-title-bg;
}

.background-test1 {
  background-color: $clicked-heart;
}

.background-test2 {
  background-color: $bsc;
}

.background-test3 {
  background-color: $hunt;
}
.background-test4 {
  background-color: $pancake;
}
.background-test5 {
  background-color: $eth;
}

.list-divider {
  background-color: $list-divider;
}

.background-primary-hover {
  transition: 150ms background-color ease-in;

  &:hover {
    background-color: $primary-dark;
  }
}

.background-white-hover {
  transition: 150ms background-color ease-in;

  &:hover {
    background-color: $neutrals-6;
  }
}

@for $i from 0 through 2000 {
  .animation-delay-#{$i} {
    animation-delay: #{$i}ms;
  }
}

.background-image-contain {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.background-image-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.line-divider {
  height: 1px;
}

.placeholder-neutrals-4 {
  &::placeholder {
    color: $neutrals-4;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $neutrals-4;
  }

  &::-ms-input-placeholder {
    color: $neutrals-4;
  }
}

.placeholder-neutrals-1 {
  &::placeholder {
    color: $neutrals-1;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $neutrals-1;
  }

  &::-ms-input-placeholder {
    color: $neutrals-1;
  }
}

.button-focused-none {
  &:focus {
    outline: none;
  }
}

.bottun-focused-neutrals-1 {
  &:focus {
    outline: $neutrals-1 2px solid;
  }
}

.text-hover {
  color: $neutrals-1;
  &:hover {
    color: $primary;
  }
}

.hover-to-primary-border {
  &:hover {
    outline: $primary 1px solid;
  }
}

.hover-shadow {
  transition: box-shadow 150ms ease-in-out;

  &:hover {
    box-shadow: rgba(100, 100, 100, 0.2) 0px 1px 8px 0px;
  }
}

.full-screen-invisible {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: 'red';
}

.z-1 {
  z-index: 1;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.hidden-input {
  background: transparent;
  border-width: 0px;
  line-height: 1.15;
}

.backdrop {
  position: absolute;
  left: 0;
  top: 0;
}

.right-absolute {
  position: absolute;
  top: 0;
  right: 0;
}

.center-absolute {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-zindex {
  z-index: $header-zindex;
}

.modal-zindex {
  z-index: $modal-zindex;
}

.sign-up-hover:hover {
  color: $white !important;
}

.text-hover {
  color: $neutrals-1;
  &:hover {
    color: $primary;
  }
}

.show-pc {
  display: flex;
  @include responsive(tablet) {
    display: none;
  }
}
.show-pc-tablet {
  display: flex;
  @include responsive(mobile) {
    display: none;
  }
}
.show-tablet-mobile {
  display: none;
  @include responsive(tablet) {
    display: flex;
  }
}
.show-mobile {
  display: none;
  @include responsive(mobile) {
    display: flex;
  }
}
