.profile-sticker {
  background-color: white;

  &:hover {
    background-color: $neutrals-6;
  }
}

.profile {
  width: 64px;
  height: 64px;
}

.artist-name {
  font-size: 20px;
}
