.news-page {
  margin-top: 80px;
  @include responsive(tablet) {
    margin-top: 70px;
  }
  .slider-section {
    padding-top: 122px;
    padding-bottom: 104px;
    background-color: #f3f8fb;
    .news-slider-title {
      line-height: 64px !important;
    }
    .content {
      @include overflowLine(2, 3);
    }
  }
  .news-wrap {
    width: $col-4;
    @include responsive(ipadpro) {
      width: $col-3;
    }
    @include responsive(tablet) {
      width: $col-2;
    }
    @include responsive(foldable) {
      width: $col-1;
    }
    .sticker-wrap {
      grid-template-columns: repeat(4, 1fr);
      gap: 32px;
      @include responsive(ipadpro) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include responsive(tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(foldable) {
        grid-template-columns: repeat(1, 1fr);
      }

      .round-sticker {
        width: $col-1;
        background: #ffffff;
        box-shadow: 5px 6px 24px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
