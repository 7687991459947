.indicator {
  bottom: 0;
  margin-top: 2.5rem;
  z-index: 9999;
  @include responsive(foldable) {
    margin-top: 1.5rem;
  }
  .indicator-blocks {
    .block {
      width: 20px;
      height: 4px;
      transition: width 400ms linear;
      &.activate {
        width: 80px;
        background-color: #1734ea;
      }
    }
    .block + .block {
      margin-left: 16px;
    }
  }
  .main-indicator-blocks {
    .block {
      width: 40px;
      height: 4px;
      transition: width 400ms linear;
      &.activate {
        width: 40px;
        background-color: $neutrals-1;
      }
    }
  }
}
